import { defineMessages } from 'react-intl';

export const scope = 'posts';

export default defineMessages({
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back'
  }
});
