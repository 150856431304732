import { useState, useEffect } from 'react';
import { useIntl, navigate } from 'gatsby-plugin-intl';

const languages = {
  en: 'en_US',
  fr: 'fr_FR'
};

export default (post = {}, pathname = '') => {
  const { locale } = useIntl();
  const [result, setResult] = useState([]);
  useEffect(() => {
    if (languages[locale] === post.language.locale) {
      setResult(post);
    } else if (post.translations.length > 0) {
      navigate(
        pathname
          .replace(`${locale}/`, '')
          .replace(post.slug, post.translations[0].slug)
      );
    } else {
      setResult(post);
    }
  }, [locale]);
  return result;
};
