import React from 'react';
import { graphql } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';

import { Layout, Image } from '../../components';
import useTranslatedPost from '../../utils/useTranslatedPost';
import messages from '../../messages/posts';

const ArticlePage = ({ data: { wpPost }, location: { pathname } }) => {
  const intl = useIntl();
  const article = useTranslatedPost(wpPost, pathname);

  return (
    <Layout fluid>
      <div className="d-flex post-main-container activities-navigation mb-3 container">
        <Link to="/activities">{`< ${intl.formatMessage(messages.back)}`}</Link>
        <div className="mx-3"> - </div>
        <div>
          {intl.locale === 'en' ? article.dateAgoEn : article.dateAgoFr}
        </div>
      </div>
      <div className="container">
        <div
          className="post-title mb-5"
          dangerouslySetInnerHTML={{ __html: article.title }}
        />
      </div>

      <Image
        src={
          article.featuredImage?.node?.localFile?.publicURL ||
          article.featuredImage?.node?.sourceUrl
        }
        alt={article.title}
        width="100%"
        height={500}
        className="mb-5"
      />
      <div className="container">
        <div
          dangerouslySetInnerHTML={{ __html: article.content }}
          className="post-body"
        />
      </div>
    </Layout>
  );
};

export default ArticlePage;
export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }, author: { node: { slug: { ne: "admin" } } }) {
      id
      slug
      title
      content
      dateAgoFr: date(fromNow: true, locale: "fr")
      dateAgoEn: date(fromNow: true, locale: "en")
      date(formatString: "D/MM/YYYY")
      excerpt
      tags {
        nodes {
          id
          slug
          name
        }
      }
      categories {
        nodes {
          id
          slug
          name
        }
      }
      author {
        node {
          id
          slug
          name
          avatar {
            url
          }
        }
      }
      featuredImage {
        node {
          id
          sourceUrl
          localFile {
            publicURL
          }
        }
      }
      translations {
        slug
      }
      language {
        locale
        slug
      }
    }
  }
`;
